import React, { Component } from "react";
import { Dropdown, Menu, Button, Space, Row, Col, Checkbox } from "antd";
import "antd/dist/antd.compact.css";
import { DownOutlined, PlusOutlined, EditOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import FoodingService from "../../service/FoodingService";
import * as CommonFunc from "./functions/CommonFunc.js";
import SpaceModal from "./SpaceModal";
import moment from "moment";
const config = require("../../config/config.js");

const CheckboxGroup = Checkbox.Group;

export default class SpaceDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSpaceModalVisible: false,
      checkedList: [],
      checkAll: true,
      spaceList: [],
      selectedSpace: null, // 체크 되어진 space 이름들을 가져온다 2개이상이면 '~외 1개' 라고 표시된다.
      spaceRerender: 0,
      currentSpaceData: null,
      isEditable: sessionStorage.getItem("admin"),
    };
  }

  // 마운트 시 호출
  componentDidMount() {
    this.getSpaceList();
  }

  // 전체 체크박스를 클릭한 경우, 체크된 경우 전체, 아니면 빈배열을 가져온다. 그리고 필터초기화 하고 전체스페이스로 필터 조건을 적용시킨다.
  onCheckAllChange = (e) => {
    let allCheckedList = e.target.checked ? this.state.spaceList.map((space) => space.space_idx) : [];

    if (allCheckedList.length > 0) {
      this.props.initFilter();
    }

    sessionStorage.setItem("checkedSpaceList", JSON.stringify(allCheckedList));

    // this.props.applySpaceFilter({ space_idx: allCheckedList });
    this.props.applySpaceFilter();
    this.setState({
      checkedList: e.target.checked ? allCheckedList : [],
      checkAll: e.target.checked,
      selectedSpace: null,
    });
  };

  onChangeCheckbox = (list) => {
    this.props.initFilter();
    this.props.applySpaceFilter({ space_idx: list });
  

    let isAllChecked = list.length === this.state.spaceList.length;
    let filteredList = [];
    let currentSpaceText = "";

    // filteredList는 체크된 리스트를 필터링하기위한 변수, DropDown에 항목표시를 위해 사용
    if (!isAllChecked) {
      filteredList = this.state.spaceList.filter((space) => list.includes(space.space_idx));
    }

    if (filteredList.length > 1) {
      currentSpaceText = `${filteredList[0].space_title} 외 ${filteredList.length - 1} 건`;
    } else if (filteredList.length > 0) {
      currentSpaceText = filteredList[0].space_title;
    }

    sessionStorage.setItem("checkedSpaceList", JSON.stringify(list));

    this.setState({
      checkedList: list,
      checkAll: isAllChecked,
      selectedSpace: currentSpaceText,
    });
  };

  getSpaceList = async () => {
    FoodingService.getSpaceList()
      .then((res) => {
        let filteredSpaceList = [];
        let checkedSpaceList = sessionStorage.getItem("checkedSpaceList") ?  JSON.parse(sessionStorage.getItem("checkedSpaceList"))  : res.data.map((space) =>  space.space_idx);
        let currentSpaceText = "";
        if (res.status === 200) {
          if (sessionStorage.getItem("admin") || config.adminUserList.includes(this.props.userId)) {
            filteredSpaceList = res.data.filter(space => checkedSpaceList.indexOf(space.space_idx) !== -1);
          } else {
            filteredSpaceList = res.data.filter(
              (space) =>
                space.space_state === 0 &&
                space.space_publish_type === 0 &&
                (space.space_date_type === 0 || (space.space_date_type === 1 && moment().diff(moment(this.state.selectedSpaceInfo?.space_end_date)) < 0))
            );
          }
          
          if (filteredSpaceList.length > 1) {
            currentSpaceText = `${filteredSpaceList[0].space_title} 외 ${filteredSpaceList.length - 1} 건`;
          } else if (filteredSpaceList.length > 0) {
            currentSpaceText = filteredSpaceList[0].space_title;
          }
          
          this.props.updateSpaceList(res.data);

          this.setState(
            {
              spaceList:  res.data,
              checkedList: checkedSpaceList,
              selectedSpace: currentSpaceText,
              checkAll: checkedSpaceList.length === res.data.length
            },
            () => {
              this.props.applySpaceFilter({ space_idx: filteredSpaceList.map((space) => space.space_idx) });
            }
          );
        } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.message);
      });
  };

  deleteSpace = (space_idx) => {
    let isDelete = window.confirm("스페이스를 삭제하시겠습니까?");

    if (!isDelete) {
      return;
    }

    FoodingService.deleteSpace(space_idx)
      .then((res) => {
        if (res.data?.rowCount === 1) {
          CommonFunc.openNotification("success", "삭제가 완료됐습니다.", "");
          this.getSpaceList();
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "삭제를 실패했습니다.", err?.response?.message);
      });

    this.setState({ isSpaceModalVisible: false });
  };

  showSpaceModal = (space) => {
    let rerender = this.state.spaceRerender;
    this.setState({
      currentSpaceData: space,
      spaceRerender: rerender + 1,
      isSpaceModalVisible: true,
    });
  };

  render() {
    return (
      <>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Space size="large">
              <Dropdown
                trigger={["click"]}
                overlay={
                  <>
                    <Row>
                      <Col span={24}>
                        <Menu>
                          <Menu.Item key="select-title" disabled>
                            <Row justify="space-between">
                              <Col span={18}>
                                <span style={{ color: "#000000" }}>스페이스 선택</span>
                              </Col>
                              <Col span={6}>
                                {this.state.isEditable && (
                                  <Button
                                    style={{ width: "40%", display: "inline-block" }}
                                    icon={<PlusOutlined />}
                                    type="link"
                                    onClick={() => this.setState({ isSpaceModalVisible: true, spaceRerender: this.state.spaceRerender + 1, currentSpaceData: null })}
                                  >
                                    New
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </Menu.Item>
                          <Menu.Divider />
                          <Menu.Item key="all">
                            <Row justify="space-between">
                              <Col>
                                <Checkbox onChange={this.onCheckAllChange} checked={this.state.checkAll}>
                                  전체
                                </Checkbox>
                              </Col>
                            </Row>
                          </Menu.Item>
                          <Menu.Item key={"space-menu"} style={{ backgroundColor: "white", height: "100%" }}>
                            <CheckboxGroup key="check-group" onChange={this.onChangeCheckbox} value={this.state.checkedList} style={{ width: "100%", height: "100%" }}>
                              {this.state.spaceList.map(
                                (space) =>
                                  (space.space_publish_type === 0 || sessionStorage.getItem("admin") || config.adminUserList.includes(this.props.userId)) && (
                                    <Row justify="space-between" key={"space:" + space.space_idx} style={{ height: "32px", lineHeight: "32px" }}>
                                      <Col>
                                        <Checkbox key={"Check" + space.space_idx} value={space.space_idx}>
                                          {space.space_title}
                                        </Checkbox>
                                      </Col>
                                      {this.state.isEditable && (
                                        <Col>
                                          {space.space_publish_type === 1 && <UserOutlined />}
                                          {space.space_publish_type === 2 && <LockOutlined />}
                                          <Button type="text" shape="circle" icon={<EditOutlined />} onClick={() => this.showSpaceModal(space)}></Button>
                                          {/* <Button type="text" shape="circle" icon={<DeleteOutlined />} onClick={() => this.deleteSpace(space.space_idx)}></Button> */}
                                        </Col>
                                      )}
                                    </Row>
                                  )
                              )}
                            </CheckboxGroup>
                          </Menu.Item>
                        </Menu>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Button style={{ width: "250px" }}>
                  <Row justify="space-between">
                    <Col span={20} style={{ textAlign: "start", textOverflow: "ellipsis", overflow: "hidden" }}>
                      {this.state.checkAll ? "전체" : this.state.selectedSpace}
                    </Col>
                    <Col span={2}>
                      <DownOutlined />
                    </Col>
                  </Row>
                </Button>
              </Dropdown>
            </Space>
          </Col>
        </Row>
        <SpaceModal
          isSpaceModalVisible={this.state.isSpaceModalVisible}
          key={"SpaceModal" + this.state.spaceRerender}
          onReloadSpace={this.getSpaceList}
          spaceData={this.state.currentSpaceData}
          deleteSpace={this.deleteSpace}
        />
      </>
    );
  }
}
