module.exports = {
  // 레드마인 Host url
  //serverUrl: "http://10.106.222.181:8000",
  // serverUrl: "http://localhost:8000",
  serverUrl: "https://fooding.somansa.com",

  // 제품명
  productNameList: ["공통", "DB-i", "Privacy-i", "Server-i", "Mail-i", "WebKeeper", "WebKeeperSG", "EDR", "내PC지키미" , "기타"],
  // 의견 분류 
  problemTypeList: ["대기", "단순 개선", "기능 개발", "로드맵", "보류"],
  // 검토현황 목록 - 관리자가 올린 아이디어에 대한 평가
  feedbackList: ["미확인", "내용확인중", "채택(개선중)", "채택(개선 완료)", "미채택"],
  // 점수 목록
  scoreList: [1, 2, 3, 4, 5],
  priorityList: ["높음", "보통", "낮음"],
  // 페이지 사이즈
  page_size: 50,
  // 페이징 옵션
  pageSizeOptions: [20, 50, 100, 500],
  // 색상 선택
  colorList: [
    "[없음]",
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#795548",
    "#607d8b",
  ],
  adminUserList: ["hkchoi@somansa.com", "jshwang@somansa.com", "hwan77@somansa.com", "badasea@somansa.com"],
};
